<template>
  <div class="root">
    <div class="table-box">
      <el-table :data="users">
        <el-table-column label="ID" prop="id" width="100"></el-table-column>
        <el-table-column label="USERNAME" prop="username" width="200"></el-table-column>
        <el-table-column label="PHONE" prop="phone" width="200"></el-table-column>
        <el-table-column label="EMAIL" prop="email" width="200"></el-table-column>
        <el-table-column label="CREATED" prop="created_at" width="200"></el-table-column>
        <el-table-column label="PUDATED" prop="updated_at" width="200"></el-table-column>
        <el-table-column label="OPS" width="200"></el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="page_info.current"
        :page-size="page_info.per_page"
        :total="page_info.total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AdminUser',
  data () {
    return {
      users: [],
      page_info: {
        current: 1,
        total: 1,
        per_page: 12
      }
    }
  },
  methods: {
    getUsers () {
      this.$axios.get('/api/user')
        .then(res => {
          this.users = res.data.data
          this.page_info.current = res.data.current_page
          this.page_info.total = res.data.total
          this.page_info.per_page = res.data.per_page
        })
        .catch(err => console.log(err))
    },
  },
  mounted () {
    this.getUsers()
  }
}
</script>
<style lang="stylus" scoped>
.root
  margin 12px auto
  .table-box
    margin 0 atuo
</style>
